import React from "react";
import AautiText from "./AautiText";
import "./styles.scss";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { globalColors } from "../../themes/GlobalColors";

function JoinButton({
  title,
  onClick,
  className = "click",
  disabled,
  buttonStyle,
  outlined = false,
  hasIcon,
  icon,
  text,
  text2 = "",
  height = "50px",
  width = "160px",
  bgColor = globalColors?.AAUTI_THEME_COLOR,
  borderColor = globalColors?.AAUTI_THEME_COLOR,
  color = "#000000",
  textStyle,
  type,
  id,
  loading = false,
}) {
  const {
    desktop,
    lapy,
    miniLapys,
    ipodDevices,
    aboveLargeMobileDevices,
    mobileDevices,
  } = useSelector((state) => state.responsive);
  const defaultStyle = {
    height: mobileDevices ? "auto" : ipodDevices ? "45px" : height,
    width: width
      ? width
      : mobileDevices
      ? "150px"
      : ipodDevices
      ? "155px"
      : width || "fit-content",
    background: disabled ? "lightgrey" : outlined ? "transparent" : bgColor,
    borderStyle: disabled ? "none" : outlined && "solid",
    borderColor: disabled ? "none" : outlined ? borderColor : "none",
    borderWidth: disabled ? "none" : outlined ? "1px" : "0px",
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    gap: icon && 10,
    padding: mobileDevices ? "5px" : "10px",
    justifyContent: "center",
    fontWeight: 500,
    ...buttonStyle,
  };

  const titleStyles = {
    fontWeight: icon && 600,
    color: disabled ? "#fff" : outlined ? color : "#ffffff",
    ...textStyle,
  };
  return (
    <button
      id={id ?? title}
      style={defaultStyle}
      className={className}
      type={type && type}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <CircularProgress sx={{ color: outlined ? "#000" : "#fff" }} />
      ) : (
        <>
          {icon}
          <div>
            {icon && (
              <AautiText
                size={"small"}
                title={text}
                textStyle={{
                  color: disabled ? "#fff" : outlined ? "#000000" : "#ffffff",
                  textAlign: "left",
                }}
              />
            )}
            <AautiText size={"normal"} title={title} textStyle={titleStyles} />
            {text2 !== "" ? (
              <AautiText size={"small"} title={text2} textStyle={titleStyles} />
            ) : null}
          </div>
        </>
      )}
    </button>
  );
}

export default JoinButton;
